import { isPlatformServer, NgClass } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Bar, StoryblokAudience } from 'src/_model/storyblock';
import { CmsService } from 'src/_service/CmsService';
import { CmsButtonComponent } from '../cms-button/cms-button.component';
import { SafeHtml } from '@angular/platform-browser';
import { GlobalService } from 'src/_service/GlobalService';
import { environment } from 'src/_environments/_environment';

@Component({
  selector: 'app-cms-bar',
  standalone: true,
  templateUrl: './cms-bar.component.html',
  styleUrl: './cms-bar.component.scss',
  imports: [NgClass, CmsButtonComponent],
  host: { ngSkipHydration: 'true' },
})
export class CmsBarComponent {

  env = environment;

  showBar: boolean = true;

  barBody: SafeHtml;
  bodyBlocks: any[]

  private _bar: Bar
  @Input() set bar(value: Bar) {
    this._bar = value;
    if (value.body) {
      this.barBody = this._cmsService.render_rt(value.body)
    }
    value.body.content.forEach(element => {
      if (element.type !== 'blok') return;
      this.bodyBlocks = element.attrs?.body
    });

    this.showBar = this.checkShowBar(this._bar)

  };
  get bar() { return this._bar };

  constructor(
    private _cmsService: CmsService,
    private _globalService: GlobalService,
    @Inject(PLATFORM_ID) private platformId,
  ) { }

  checkShowBar(bar: Bar): boolean {

    if(isPlatformServer(this.platformId))return false;

    if(!this.env.production) return true;

    const visits = this._globalService.visits;

    if (bar.audience === StoryblokAudience.CUSTOMERS && !visits.customerid)
      return false

    if (bar.audience === StoryblokAudience.NON_CUSTOMERS && visits.customerid)
      return false

    //both empty or 0 tm x min en x > first_visit
    if (!bar.show_from_minutes_from_first_visit && (!bar.show_to_minuts_from_first_visit || bar.show_to_minuts_from_first_visit > visits.minutesSinceFirstVisit))
      return true;

    //start not empty and between start and end if has end
    if (bar.show_from_minutes_from_first_visit && bar.show_from_minutes_from_first_visit <= visits.minutesSinceFirstVisit && (!bar.show_to_minuts_from_first_visit || bar.show_to_minuts_from_first_visit > visits.minutesSinceFirstVisit))
      return true;

    return false;
  }


}
