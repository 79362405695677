@if (showBar && bar) {
    <div class="bg-e-white" [ngClass]="{'sticky top-20 md:top-36 z-20': bar.sticky}">
        <div class="min-h-7 flex flex-row  content-center justify-center gap-1 px-2 pb-1 bg-{{bar.bgcolor}}" [ngClass]="{'w-full max-w-[1428px] mx-auto rounded-lg': !bar.fullwidth}">
            @if(bar.body){
                <div class="text-{{bar.text_color}} flex content-center my-auto" [innerHTML]="barBody"></div>
                @for (block of bodyBlocks; track $index) {
                    <app-cms-button [removeMargin]="true" [button]="block" class="flex my-auto"></app-cms-button>
                }
            }
        </div>
    </div>
}